<template>
	<section id="services" class="py-10">
		<v-container fluid>
			<p class="text-h4 text-center font-weight-bold text-uppercase mb-2">Punto de inicio</p>
			<p class="text-h6 text-center mx-auto" style="max-width: 800px">
				Desde encontrar apuntes, resolver dudas sobre tus asignaturas hasta a encontrar compañeros con los que hacer un trabajo. Todo lo que necesitas
				está aquí.
			</p>
			<v-card max-width="1200" class="mt-15 mx-auto d-flex flex-wrap justify-space-around" flat tile color="transparent">
				<v-img height="300" contain src="@/assets/images/landing/online-learning-concept.svg" />
				<div class="d-flex flex-column justify-space-around">
					<div class="flex-grow-0 d-flex">
						<div>
							<v-icon size="50" class=""> mdi-cloud-download </v-icon>
						</div>
						<div class="mt-3 ml-5">
							<p class="text-h5 font-weight-bold mb-2">Sube y descarga apuntes</p>
							<p class="text-subtitle-1">Puedes utilizar nuestro explorador web o acceder desde Google Drive.</p>
						</div>
					</div>
					<div class="flex-grow-0 d-flex">
						<div>
							<v-icon size="50" class=""> mdi-message-outline </v-icon>
						</div>
						<div class="mt-3 ml-5">
							<p class="text-h5 font-weight-bold mb-2">Resuelve tus dudas</p>
							<p class="text-subtitle-1">Dentro de cada asignatura podrás comentar dudas con tus compañeros.</p>
						</div>
					</div>
					<div class="flex-grow-0 d-flex">
						<div>
							<v-icon size="50" class=""> mdi-share </v-icon>
						</div>
						<div class="mt-3 ml-5">
							<p class="text-h5 font-weight-bold mb-2">Encuentra información de tus asignaturas</p>
							<p class="text-subtitle-1">Estar informado nunca está de más...</p>
						</div>
					</div>
				</div>
			</v-card>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandingServices',
}
</script>
